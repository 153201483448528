.embla {
    position: relative;
    /*background-color: #f7f7f7;*/
    /*padding: 20px;*/
    /*max-width: 670px;*/
    margin-left: auto;
    margin-right: auto;
}

.embla__viewport {
    overflow: hidden;
    width: 100%;
}

.embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
}

.embla__viewport.is-dragging {
    cursor: grabbing;
}

.embla__container {
    display: flex;
    margin-left: -10px;

    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.embla__container.noselect {
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.embla__slide {
    position: relative;
    max-width: 100%;
    min-width: 100%;
    padding-left: 10px;
}

.dlg-slider .embla__slide {

}

.embla__slide__inner {
    position: relative;
    /*overflow: hidden;*/
    /*height: 190px;*/
}

.embla__slide__img {
    /*position: absolute;*/
    display: block;
    /*top: 50%;*/
    /*left: 50%;*/
    /*width: auto;*/
    /*min-height: 100%;*/
    /*min-width: 100%;*/
    /*max-width: 100%;*/
    /*transform: translate(-50%, -50%);*/
}

.embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    /*position: absolute;*/
    /*z-index: 1;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*border: 0;*/
    /*width: 30px;*/
    /*height: 30px;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*fill: #1bcacd;*/
    padding: 0;
}

.hero-section .embla__button {

}

.tt-slider div:has(>.embla__button:disabled+.embla__button:disabled) {
    @apply border-none
}
.tt-slider .embla__button {
    @apply text-teal rounded-full py-4
}

.tt-slider .embla__button--prev {
    @apply pl-6 pr-3
}

.tt-slider .embla__button--next {
    @apply pr-6 pl-3
}

.embla__button:disabled {
    cursor: default;
    opacity: 0.3;
}

.dlg-slider .embla__button:is(:active, :hover):not(:disabled) {
    @apply bg-teal text-white;
}
.dlg-slider .embla__button.placeh {
    visibility: hidden;
    box-sizing: content-box;
    @apply w-6;
}
.dlg-slider .embla__button{
/*<div className="inline-flex space-x-4 items-center justify-end w-32 h-14">*/
/*<div className="w-14 h-14">*/
@apply /*flex items-center justify-center w-14 h-14*/ p-3 border-2 rounded-lg border-teal text-teal;
/*<div className="flex-1 h-full">*/
/*<div className="flex-1 h-full rounded">*/
/*<img className="flex-1 h-full rounded-lg" src="https://via.placeholder.com/24x24"/>*/
/*<div className="w-14 h-full">*/
/*<div className="relative bg-white rounded-lg" style={{width: 56, height: 56,}}>*/
/*<div className="w-14 h-14 bg-green-500 rounded-lg"/>*/
/*<div className="w-6 h-6 absolute m-auto inset-0">*/
/*<div className="flex-1 h-full rounded">*/
/*<img className="flex-1 h-full rounded-lg" src="https://via.placeholder.com/24x24"/>*/
}

/*.embla__button__svg {
    width: 100%;
    height: 100%;
}*/

.embla__button--prev {
    left: 27px;
}

.embla__button--next {
    right: 27px;
}

.embla__dots {
    display: flex;
    list-style: none;
    justify-content: center;
    padding-top: 10px;
}

.embla__dot {
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    width: 24px;
    height: 24px;
    /*margin-right: 4px;*/
    /*margin-left: 4px;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.embla__dot:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 100%;

    background-color: #86B0B0;
}

.embla__dot.is-selected:after {
    background-color: #058B8C;
    border-color: #FFFFFF;
    opacity: 1;
    box-shadow: 0px 0px 4px 0px #00000040;

}
