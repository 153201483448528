
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

* {
    /*outline: 1px solid lightgray;*/
}

.wrapper {
    grid-template-columns: auto 1fr;
}

section:has(.section-content) {
    @apply pt-8 pb-8 pt-8;
}

section:has(.section-content):is(section:not(.dark) + .dark, section.dark + :not(.dark)) {
    @apply sm:pt-12;
}

section:has(.section-content):is(.dark:has(+ section:not(.dark)), :not(.dark):has(+ section.dark)) {
    @apply sm:pb-24;
}

:root {
    /*--section-content-margin-x: calc(50% - var(--section-content-container-width) / 2);*/
    /*--section-content-margin-right: 0px;*/

    /*--section-content-container-width:0px;*/

    /*@apply xl:max-w-screen-lg 2xl:max-w-screen-xl mx-auto px-8;*/
}

.section-content {
    @apply
    [--section-content-margin-x:5%]
    /*sm:[--section-content-margin-x:2rem]*/
    /*md:[--section-content-margin-x:3rem]*/
    [--section-content-container-width:calc(100%_-_2_*_var(--section-content-margin-x))]
    xl:[--section-content-container-width:1024px]
    2xl:[--section-content-container-width:1280px]
    xl:[--section-content-margin-x:calc(50%_-_var(--section-content-container-width)_/_2)]
}


.h2special::after {
    content: "";
    /*border-bottom: 10px solid white;*/
    @apply border-b absolute right-0 left-[-100vw] block;
}

.dlg-slider h2:not(.h2special)::before {
    display: none;
}

h2:not(.h2special) {
/*@apply xl:pl-[1em]*/
}
h2:not(.h2special)::before {
    content: "";
    display: inline-block;
    @apply w-[2em] xl:w-[1.5em] border-b-2 border-teal mr-[0.5em] xl:mr-[1.5em] relative top-[-2px] xl:ml-[-3em];
    vertical-align: middle;
    /*transform: translate(-0.5em, -3px);*/
}

.apex, .apex * {
    font-family: 'apex', 'Ubuntu', 'Droid Sans', sans-serif !important;
}

section, footer {
    @apply bg-gray-100 dm:bg-teal;
    @apply bodytxt;
}

footer h4 {
    @apply text-2xl mb-0;
}

section.roboto, section.roboto {
    font-family: 'Roboto', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.hyphens-auto {
    hyphens: auto;
}

.hero-service-slider-text {
    overflow: visible;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    hyphens: auto;
}


ol {
    counter-reset: ol-counter;
}

ol li::before {
    /*float: left;*/
    display: inline-block;
    --tw-content: "0" counter(ol-counter);
    content: "0" counter(ol-counter);
    counter-increment: ol-counter;
    font-family: 'Century Gothic', sans-serif;
    @apply text-4xl font-bold mb-[0.3em];
    @apply text-neutral-300 dm:text-white;
}
ol li {
    @apply before:hover:text-white dm:before:hover:text-neutral-300;
}


h1 {
    @apply clrtxt opacity-20
}

.heading_with_clrtxt :not(:is(br, strong)) {
    display: contents;
}

.heading_with_clrtxt strong {
    @apply clrtxt
}

.dlborder {
    @apply border-black dm:border-white
}

.clrborder {
    @apply border-teal dm:border-white
}

.dltxt {
    @apply text-black dm:text-white
}

.clrtxt {
    @apply text-teal dm:text-white
}

.bodytxt {
    @apply text-gray-800 dm:text-white
}


.text-content h4 {
    @apply font-black clrtxt;
}


.footer-logo-column img {
    /* equivalent of 2*h4 = 2*text-2xl and 2*text-3xl, such that the text has same height as h4 */
    @apply max-h-[3rem] sm:max-h-[3.75rem]
}