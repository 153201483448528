
.hero-grid {
    @apply grid;
    grid-template-areas: "top" "bottom";
    grid-template-rows: 50% 50%;
}
.hero-grid-top {
    grid-area: top;
}
.hero-grid-bottom {
    grid-area: bottom;
}
.hero-grid-full {
    grid-area: top/top / bottom/bottom;
}