@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    height: 100%;
}
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*@apply bg-green-500 border-none;*/
    @apply bg-gray-100;
    @apply gothic;
}

noscript {
    @apply block;
}

#root, .root {
    /*height: 100vh;*/
    border: none;
    /*display: contents;*/
}


@layer base{
    .section-content {
        @apply max-w-full
            mx-[var(--section-content-margin-x)]
        xl:w-[var(--section-content-container-width)]
            /*xl:max-w-screen-lg 2xl:max-w-screen-xl*/
        xl:mx-auto
        /*px-8;*/
    }

}

@layer components {
    .card {
        background-color: theme('colors.white');
        border-radius: theme('borderRadius.lg');
        padding: theme('spacing.6');
        box-shadow: theme('boxShadow.xl');
    }

    /* ... */
    input, textarea {
        @apply border border-neutral-300 p-4;
        /* Input */
        /*
        TODO:
        box-sizing: border-box;
                border: 1px solid #A9A9A9;
        */

    }

    :is(input, textarea):focus {
        @apply border-neutral-800 rounded-none;
        /* TODO: Input */
        /*box-sizing: border-box;
        border: 1px solid #202C38;
        */
    }

    input[type="submit"] {
        @apply uppercase bg-neutral-700 font-bold text-white;
    }
}

/* Style */
.gothic {
    font-family: 'Century Gothic', sans-serif;
}

.roboto {
    font-family: 'Roboto', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

h1, h2, h3 {
    @apply gothic;
    /*hyphens: auto;*/
}

h1, h2 {
    @apply uppercase;
}

h2, h3, h4, h5 {
    @apply font-bold;
}

dt {
    @apply uppercase
}


h1 {
    @apply text-6xl sm:text-7xl md:text-8xl lg:text-9xl text-teal dark:text-white mb-4 opacity-20;
}

h2, h3, h4, h5 {
    @apply mb-[0.8em];
}


h2 {
    @apply text-lg;
}

h3 {
    @apply text-4xl sm:text-4xl md:text-5xl;
}

h4 {
    @apply text-2xl sm:text-3xl;
    /*hyphens: auto;*/
}
.tt-slider h4 {
    @apply md:text-4xl;
}

h5 {
    @apply text-xl leading-normal;
}

h6 {
    @apply text-xl;
}
